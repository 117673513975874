import { Box } from 'rebass'
import styled from 'styled-components'
import { media } from '../../theme/'

export const Container = styled(Box)`
  width: 100%;
  padding-left: ${props => props.post ? '0' : ''};
  
  & * {
    z-index: 2;
  }

  & .slick-slider {
    margin: 1.5rem auto 4rem;
    &:before {
    content: '';
    position: absolute;
    z-index: 0;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
    width: 95%;
    height: 95%;
    opacity: 0.4;
    box-shadow: 0 25px 25px 0px rgba(0,0,0,0.52), 0 20px 20px 0 rgba(0,0,0,0.48);
  }
  }
  & .slick-dots {
    padding: 0;
    text-align: left;
  }
  & .slick-dots li {
    margin: 0;  

    & button:before {
      color: white;
    }
    &.slick-active button:before {
      color: white;
    }
  }
  & .slick-thumb {
    bottom: -45px;
  }
  & .slick-thumb li {
    width: 60px;
    height: 45px;
  }
  & .slick-thumb li img {
    filter: grayscale(100%);
  }
  & .slick-thumb li.slick-active img {
    filter: grayscale(0%);
  }
  margin-bottom: -55px;
  
  ${media.LargeScreenUp`
    margin-bottom: -25px;
    margin-left: ${props => props.post ? 'calc(-18% + 20px)' : '0'};
    width: ${props => props.post ? 'calc(100% + 18%)' : '100%'};
    & .slick-dots {
      position: absolute;
      z-index: 20;
      bottom: -53px;
      left: 12vw;
      width: 76vw;
    }
  `}
  ${media.GiantScreenUp`
  margin-left: ${props => props.post ? 'calc(-15.5% + 20px)' : '0'};
  width: ${props => props.post ? 'calc(100% + 35.5%)' : '100%'};
    & .slick-dots {
      left: 6.5vw;
      width: 87vw;
    }
  `}
`
