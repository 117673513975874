import React from 'react'
import Slider from 'react-slick'
import Img from 'gatsby-image'
import { Container } from './styles'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Carousel = (props) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  }
  return (
    <Container pl={[0, null, '3.5vw']} pb={[0, null, 20]} post={props.post}>
      <Slider {...settings}>
        { props.images && props.images.map((image, key) => {
          const sizes = image.localFile.childImageSharp.sizes
          return <Img src={sizes.src} sizes={sizes} key={`carousel::${key}`} />
        })
        }
      </Slider>
    </Container>
  )
}
export default Carousel
